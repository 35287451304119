<template>
    <div class="page-warp min-page gray-page">
        <van-address-list
            :switchable="false"
            v-model="chosenAddressId"
            :list="addLooplist"
            @add="onAdd"
            @click-item="checkAddress"
            @edit="onEdit"
            />
    </div>
</template>
<script>
import { userAddressList } from "@/api/user";
export default {
    name:'addressList',
    data() {
        return {
            chosenAddressId: '',
            fromPage:'',
            addLooplist: [
            ],
        };
    },
    created() {
        this.fromPage = this.$route.query.from;
        this.getUserAddressList();
    },
    methods: {
        checkAddress(item){
            if(this.fromPage == 'mine'){
                this.onEdit(item);
            }else{
                let sessionData = sessionStorage.getItem('invoiceDetail');
                let invoiceDetail = sessionData ? JSON.parse(sessionData) : {};
                invoiceDetail.checkAddress = {
                    id:item.id,
                    recipientName:item.name,
                    recipientPhone:item.tel,
                    recipientAddress:item.address,
                };
                sessionStorage.setItem('invoiceDetail',JSON.stringify(invoiceDetail));
                this.$router.push({
                    path:'/toInvoice',
                })    
            }
        },
        onAdd() {
            this.$router.push({
                path:'/addressAdd',
                query:{
                    from:this.fromPage
                }
            })
        },
        onEdit(row) {
            this.$router.push({
                path:'/addressEdit',
                query:{
                    id:row ? row.id : '',
                    from:this.fromPage
                }
            })
        },
        //   通过id查询订单信息
        async getUserAddressList() {
            let res = await userAddressList();
            res.rows.map(item=>{
                this.addLooplist.push({
                    id: item.id,
                    name: item.recipientName,
                    tel: item.mobile,
                    address:item.recipientAddress,
                })
            });
        },
    },
};
</script>
<style lang="less" scope>
</style>